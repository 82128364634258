import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./footer";

const BillpunchTermsAndConditions = () => {
  const [pageData, setPageData] = useState(null);
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();
      
      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];
  
        console.log("Pages Data:", pages); 
  
        const faqPageData = pages.find(page => page.page_id === "4");
  
        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData(); 
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.innerHTML = `
      document.addEventListener("DOMContentLoaded", function() {
          console.log("Running SEO script...");

          const seoUrl = "https://seobix.com/integrations/meta_seo.php";
          const requestBody = JSON.stringify({
              weburl: "https://billpunch.com",
              fileurl: "https://billpunch.com/SeoSettings_638749552913980001.enc"
          });

          fetch(seoUrl, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: requestBody
          })
          .then(response => response.json())
          .then(seoData => {
              console.log("SEO Data:", seoData);

              if (seoData.status !== "1" || !seoData.data || seoData.data.length === 0) {
                  console.error("Invalid SEO response format");
                  return;
              }

              const currentUrl = window.location.href.replace(/\/$/, '').replace(/^https?:\/\/www\./, 'https://');
            console.log(currentUrl);
            const page = seoData.data[0].pages.find(p => p.page_url === currentUrl);
            if (!page) {
                console.error(currentUrl+"Page not found");
                return;
            }

              const { basic_settings, og_settings, keyword_settings } = page;

              if (basic_settings.tittle) document.title = basic_settings.tittle;

              const metaTags = {
                  "description": basic_settings.meta_desc,
                  "keywords": keyword_settings.map(k => k.keyword).join(", "),
                  "og:title": og_settings.og_title,
                  "og:description": og_settings.og_desc,
                  "og:image": og_settings.og_image,
                  "og:url": og_settings.og_url,
                  "og:type": og_settings.og_type,
                  "og:site_name": og_settings.og_sitename,
                  "twitter:title": og_settings.twitter_title,
                  "twitter:description": og_settings.twitter_desc,
                  "twitter:image": og_settings.twitter_image
              };

              Object.entries(metaTags).forEach(([key, value]) => {
                  if (value) {
                      let metaTag = document.querySelector(\`meta[name="\${key}"], meta[property="\${key}"]\`);
                      if (!metaTag) {
                          metaTag = document.createElement("meta");
                          if (key.startsWith("og:") || key.startsWith("twitter:")) {
                              metaTag.setAttribute("property", key);
                          } else {
                              metaTag.setAttribute("name", key);
                          }
                          document.head.appendChild(metaTag);
                      }
                      metaTag.setAttribute("content", value);
                  }
              });
          })
          .catch(error => console.error("SEO Script Error:", error));
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
        
      <Navbar />  
      <h1 className="text-center mt-3">Terms and Conditions</h1>

      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-8">
        
          <h5>1. Your Use of the Platform:</h5>
          <ul>
            <li>Grant of Licence: Users are granted a non-exclusive licence to use the platform for internal business purposes.</li>
            <li>Usage Limits: Users must adhere to usage limits set out in the quotation.</li>
            <li>Prohibited Actions: Users are prohibited from various actions including modifying the platform, unauthorized distribution, unlawful use, and introducing viruses.</li>
          </ul>

          <h5>2. Your Account:</h5>
          <ul>
            <li>Account Creation: Users must provide accurate information for account creation.</li>
            <li>Account Security: Users are responsible for maintaining the confidentiality of their account information.</li>
            <li>Account Termination: Users can terminate their account with three months' notice.</li>
          </ul>

          <h5>3. Fees:</h5>
          <ul>
            <li>Payment: Users must pay fees in advance and bear all applicable taxes.</li>
            <li>Early Termination: Fees are payable upon early termination as the invoice specifies.</li>
          </ul>

          <h5>4. Privacy Policy:</h5>
          <ul>
            <li>Data Collection and Use: Users consent to the collection, use, and disclosure of personal data as per the privacy policy.</li>
            <li>Data Protection: BillPunch agrees to protect personal data and adhere to applicable data protection laws.</li>
            <li>Cookies and Digital Certificates: The platform may use cookies and digital certificates.</li>
          </ul>

          <h5>5. Content and Postings:</h5>
          <ul>
            <li>Submitted Content: Users are responsible for the content they submit, and BillPunch has the right to use submitted content.</li>
            <li>Third-Party Content: BillPunch is not responsible for third-party content available on the platform.</li>
          </ul>

          <h5>6. Third Party Sites and Links:</h5>
          <p>External Sites: BillPunch is not responsible for third-party sites linked to the platform.</p>

          <h5>7. Disclaimer of Warranties and Limitation of Liability (Sections 6, 7, and 10):</h5>
          <ul>
            <li>BillPunch does not warrant the suitability of third-party sites and disclaims liability for any loss incurred through their use.</li>
            <li>The platform's content, including third-party content, is general and not specific advice. BillPunch does not endorse products or services and is not responsible for decisions based on information on the platform.</li>
            <li>The platform is provided "as is" and BillPunch disclaims warranties regarding its merchantability, quality, and accuracy. BillPunch is not liable for any damages arising from platform use.</li>
          </ul>

          <h5>8. Indemnity (Section 8):</h5>
          <p>Users agree to indemnify and hold BillPunch harmless from any claims, losses, or liabilities arising from their breach of the agreement, use of the platform, or infringement of others' rights.</p>

          <h5>9. Confidentiality (Section 9):</h5>
          <p>Both parties agree to keep confidential information disclosed during the agreement confidential, with exceptions outlined for publicly available information or information independently developed.</p>

          <h5>10. Warranties and Exclusion of Liability (Section 10):</h5>
          <ul>
            <li>Both parties warrant their capacity to enter and perform the agreement. BillPunch also warrants it will fulfill its obligations with reasonable care and skill.</li>
            <li>The platform is provided with no warranties, and BillPunch is not liable for any damages arising from platform use, subject to applicable law.</li>
          </ul>

          <h5>11. Term and Termination (Section 11):</h5>
          <ul>
            <li>The agreement's duration is specified in the quotation, with either party able to terminate with three months' notice. BillPunch can terminate for breach or certain insolvency events.</li>
            <li>Upon termination, users must cease platform use, and BillPunch can delete accounts. Users remain liable for any obligations incurred.</li>
          </ul>

          <h5>12. Force Majeure (Section 12):</h5>
          <p>BillPunch is not liable for failure to perform due to events beyond its control, with the time for performance extended for the duration of the delay.</p>

          <h5>13. Assignment (Section 13):</h5>
          <p>Users cannot assign their rights and obligations under the agreement without BillPunch's consent.</p>

          <h5>14. Publicity (Section 14):</h5>
          <p>Users grant BillPunch the right to use their trade names and logos for corporate publicity or marketing.</p>

          <h5>15. Audit (Section 15):</h5>
          <p>Users have the right to audit BillPunch's facilities and records to verify compliance with the agreement, subject to certain conditions.</p>

          <h5>16. General (Section 16):</h5>
          <p>Various general provisions, including severability, cumulative remedies, waiver, and gender-neutral language.</p>

          <h5>17. Notice (Section 17):</h5>
          <p>Notices to users may be made via email, regular mail, or through the platform.</p>

          <h5>18. Contact Information (Section 19):</h5>
          <p>For questions or concerns regarding these Terms and Conditions, please contact at contact@billpunch.com.</p>
        </div>
        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default BillpunchTermsAndConditions;
