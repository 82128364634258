import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./footer";
import Nabvar from "./Navbar";
import { Helmet } from "react-helmet";
const Billpunchrefundpolicy = () => {
  const [pageData, setPageData] = useState(null);
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();
      
      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];
  
        console.log("Pages Data:", pages); 
  
        const faqPageData = pages.find(page => page.page_id === "6");
  
        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData(); 
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.innerHTML = `
      document.addEventListener("DOMContentLoaded", function() {
          console.log("Running SEO script...");

          const seoUrl = "https://seobix.com/integrations/meta_seo.php";
          const requestBody = JSON.stringify({
              weburl: "https://billpunch.com",
              fileurl: "https://billpunch.com/SeoSettings_638749552913980001.enc"
          });

          fetch(seoUrl, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: requestBody
          })
          .then(response => response.json())
          .then(seoData => {
              console.log("SEO Data:", seoData);

              if (seoData.status !== "1" || !seoData.data || seoData.data.length === 0) {
                  console.error("Invalid SEO response format");
                  return;
              }

               const currentUrl = window.location.href.replace(/\/$/, '').replace(/^https?:\/\/www\./, 'https://');
            console.log(currentUrl);
            const page = seoData.data[0].pages.find(p => p.page_url === currentUrl);
            if (!page) {
                console.error(currentUrl+"Page not found");
                return;
            }

              const { basic_settings, og_settings, keyword_settings } = page;

              if (basic_settings.tittle) document.title = basic_settings.tittle;

              const metaTags = {
                  "description": basic_settings.meta_desc,
                  "keywords": keyword_settings.map(k => k.keyword).join(", "),
                  "og:title": og_settings.og_title,
                  "og:description": og_settings.og_desc,
                  "og:image": og_settings.og_image,
                  "og:url": og_settings.og_url,
                  "og:type": og_settings.og_type,
                  "og:site_name": og_settings.og_sitename,
                  "twitter:title": og_settings.twitter_title,
                  "twitter:description": og_settings.twitter_desc,
                  "twitter:image": og_settings.twitter_image
              };

              Object.entries(metaTags).forEach(([key, value]) => {
                  if (value) {
                      let metaTag = document.querySelector(\`meta[name="\${key}"], meta[property="\${key}"]\`);
                      if (!metaTag) {
                          metaTag = document.createElement("meta");
                          if (key.startsWith("og:") || key.startsWith("twitter:")) {
                              metaTag.setAttribute("property", key);
                          } else {
                              metaTag.setAttribute("name", key);
                          }
                          document.head.appendChild(metaTag);
                      }
                      metaTag.setAttribute("content", value);
                  }
              });
          })
          .catch(error => console.error("SEO Script Error:", error));
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
   
      <Nabvar />
      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col">
          <h1 className="text-center mt-3">Refund & Cancellation Policy</h1>
          
          <h5>Conditions for Refund</h5>
          <p style={{ fontSize: "1rem" }}>
            At BillPunch, we strive to ensure customer satisfaction. We understand that there may be circumstances where a refund is warranted. Our conditions for refund include:
          </p>
          <ul>
            <li style={{ fontSize: "1rem" }}>
              <h6> Overpayment of a bill:</h6>
              If a customer has made an overpayment on their bill, they are eligible for a refund of the excess amount.
            </li>
            <li style={{ fontSize: "1rem" }}>
              <h6> Payment made for a service not rendered:</h6>
              If a customer has made a payment for a service that was not provided as agreed upon, they are entitled to a refund.
            </li>
            <li style={{ fontSize: "1rem" }}>
              <h6> Payment made in error:</h6>
              In the event of a payment made in error, we will issue a refund upon request.
            </li>
          </ul>
          
          <h5>Timeframe for Refund Requests</h5>
          <p style={{ fontSize: "1rem" }}>
            Customers can request a refund within [X] days from the date of the payment. Requests made outside of this timeframe may not be eligible for a refund.
          </p>

          <h5>Cancellation Policy</h5>
          
          <h6>Cancellation Window:</h6>
          <p style={{ fontSize: "1rem" }}>
            Customers can cancel their order or service within [X] days from the date of purchase or before the scheduled appointment/service, whichever comes first, without incurring any charges.
          </p>
          
          <h6>Cancellation Fees:</h6>
          <p style={{ fontSize: "1rem" }}>
            Cancellations made outside the allowed window may be subject to a cancellation fee. The fee amount will vary depending on the nature of the product or service.
          </p>
          
          <h6>Cancellation Process:</h6>
          <p style={{ fontSize: "1rem" }}>
            Customers can cancel their order or service by contacting our customer support team at [contact email/phone number] or through our online portal at [website link]. Our representatives will guide you through the cancellation process.
          </p>
          
          <h5>Refund for Cancellations</h5>
          <p style={{ fontSize: "1rem" }}>
            Cancellation within the allowed window will result in a full refund of the payment made. However, cancellations made outside the allowed window may result in a partial or no refund, depending on the circumstances.
          </p>
          
          <h5>Subscription Services</h5>
          <p style={{ fontSize: "1rem" }}>
            For subscription services, customers can cancel their subscription at any time through our online portal or by contacting customer support. If a cancellation is made before the end of the billing cycle, customers may be eligible for a prorated refund for the unused portion of the subscription.
          </p>
          
          <p style={{ fontSize: "1rem" }}>
            By engaging with our products and services, customers agree to abide by the terms and conditions outlined in this refund and cancellation policy. For any questions or assistance regarding refunds or cancellations, please reach out to our customer support team.
          </p>
        </div>
        <div className="col-md-1"></div>
      </div>
      <Footer />
    </>
  );
};

export default Billpunchrefundpolicy;
