import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./footer";
import Nabvar from "./Navbar";
import { GlobalContext } from "../../Context/GlobalState";
import { Accordion, Card, Container } from "react-bootstrap";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Helmet } from "react-helmet";

const Billpuncfaq = () => {
  const [faq, setfaq] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const [pageData, setPageData] = useState(null);
 
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();
      
      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];
  
        console.log("Pages Data:", pages); 
  
        const faqPageData = pages.find(page => page.page_id === "3");
  
        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };

  const getfaq = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetFaqSuperadmin`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        setfaq(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    fetchSeoData(); 
    getfaq(); 
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.innerHTML = `
      document.addEventListener("DOMContentLoaded", function() {
          console.log("Running SEO script...");

          const seoUrl = "https://seobix.com/integrations/meta_seo.php";
          const requestBody = JSON.stringify({
              weburl: "https://billpunch.com",
              fileurl: "https://billpunch.com/SeoSettings_638749552913980001.enc"
          });

          fetch(seoUrl, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: requestBody
          })
          .then(response => response.json())
          .then(seoData => {
              console.log("SEO Data:", seoData);

              if (seoData.status !== "1" || !seoData.data || seoData.data.length === 0) {
                  console.error("Invalid SEO response format");
                  return;
              }

               const currentUrl = window.location.href.replace(/\/$/, '').replace(/^https?:\/\/www\./, 'https://');
            console.log(currentUrl);
            const page = seoData.data[0].pages.find(p => p.page_url === currentUrl);
            if (!page) {
                console.error(currentUrl+"Page not found");
                return;
            }

              const { basic_settings, og_settings, keyword_settings } = page;

              if (basic_settings.tittle) document.title = basic_settings.tittle;

              const metaTags = {
                  "description": basic_settings.meta_desc,
                  "keywords": keyword_settings.map(k => k.keyword).join(", "),
                  "og:title": og_settings.og_title,
                  "og:description": og_settings.og_desc,
                  "og:image": og_settings.og_image,
                  "og:url": og_settings.og_url,
                  "og:type": og_settings.og_type,
                  "og:site_name": og_settings.og_sitename,
                  "twitter:title": og_settings.twitter_title,
                  "twitter:description": og_settings.twitter_desc,
                  "twitter:image": og_settings.twitter_image
              };

              Object.entries(metaTags).forEach(([key, value]) => {
                  if (value) {
                      let metaTag = document.querySelector(\`meta[name="\${key}"], meta[property="\${key}"]\`);
                      if (!metaTag) {
                          metaTag = document.createElement("meta");
                          if (key.startsWith("og:") || key.startsWith("twitter:")) {
                              metaTag.setAttribute("property", key);
                          } else {
                              metaTag.setAttribute("name", key);
                          }
                          document.head.appendChild(metaTag);
                      }
                      metaTag.setAttribute("content", value);
                  }
              });
          })
          .catch(error => console.error("SEO Script Error:", error));
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
   
      <Nabvar />
      <h1 className="text-center mt-3" style={{ color: "darkblue" }}>
        Frequently Asked Questions
      </h1>

      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-6 col-10 d-flex justify-content-center col">
          <Container className="m-0 p-0">
            <Accordion>
              {faq.map((item) => (
                <Card className="m-2 p-2" key={item.pk_faq_id}>
                  <Accordion.Toggle
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: ".2rem",
                      borderBottom: "1px solid rgb(170, 215, 189)",
                    }}
                    className="m-0 p-2"
                    as={Card.Header}
                    eventKey={item.pk_faq_id}
                  >
                    {item.question}
                    <div>
                      <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={item.pk_faq_id}>
                    <Card.Body
                      className="p-2"
                      style={{
                        background: "#FEFAF6",
                        border: "1px solid rgb(170, 215, 189)",
                        fontWeight: "600",
                        fontSize: ".8rem",
                        borderTop: "none",
                      }}
                    >
                      {item.answer}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </Container>
        </div>
        <div className="col-md-5 d-flex justify-content-center mt-md-5">
          <img
            className="mainpic"
            alt="FAQs section"
            style={{ height: "20rem" }}
            src={require("../../../assets/images/billpunch_faq.png")}
          />
        </div>
        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Billpuncfaq;
