import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./footer";
import Navbar from "./Navbar";
const Billpunchaboutus = () => {
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.innerHTML = `
      document.addEventListener("DOMContentLoaded", function() {
          console.log("Running SEO script...");

          const seoUrl = "https://seobix.com/integrations/meta_seo.php";
          const requestBody = JSON.stringify({
              weburl: "https://billpunch.com",
              fileurl: "https://billpunch.com/SeoSettings_638749552913980001.enc"
          });

          fetch(seoUrl, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: requestBody
          })
          .then(response => response.json())
          .then(seoData => {
              console.log("SEO Data:", seoData);

              if (seoData.status !== "1" || !seoData.data || seoData.data.length === 0) {
                  console.error("Invalid SEO response format");
                  return;
              }

           const currentUrl = window.location.href.replace(/\/$/, '').replace(/^https?:\/\/www\./, 'https://');
            console.log(currentUrl);
            const page = seoData.data[0].pages.find(p => p.page_url === currentUrl);
            if (!page) {
                console.error(currentUrl+"Page not found");
                return;
            }

              const { basic_settings, og_settings, keyword_settings } = page;

              if (basic_settings.tittle) document.title = basic_settings.tittle;

              const metaTags = {
                  "description": basic_settings.meta_desc,
                  "keywords": keyword_settings.map(k => k.keyword).join(", "),
                  "og:title": og_settings.og_title,
                  "og:description": og_settings.og_desc,
                  "og:image": og_settings.og_image,
                  "og:url": og_settings.og_url,
                  "og:type": og_settings.og_type,
                  "og:site_name": og_settings.og_sitename,
                  "twitter:title": og_settings.twitter_title,
                  "twitter:description": og_settings.twitter_desc,
                  "twitter:image": og_settings.twitter_image
              };

              Object.entries(metaTags).forEach(([key, value]) => {
                  if (value) {
                      let metaTag = document.querySelector(\`meta[name="\${key}"], meta[property="\${key}"]\`);
                      if (!metaTag) {
                          metaTag = document.createElement("meta");
                          if (key.startsWith("og:") || key.startsWith("twitter:")) {
                              metaTag.setAttribute("property", key);
                          } else {
                              metaTag.setAttribute("name", key);
                          }
                          document.head.appendChild(metaTag);
                      }
                      metaTag.setAttribute("content", value);
                  }
              });
          })
          .catch(error => console.error("SEO Script Error:", error));
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  
  }, []);

  return (
    <>
   
      <Navbar />
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col">
          <h1 className="text-center mt-3">About Us</h1>
          <p className="text-center">
            <b>
              Welcome to BillPunch, where managing your bills becomes effortless
              and stress-free. At BillPunch, we understand that juggling
              multiple bills can be overwhelming. Our mission is to simplify
              your financial management by providing a one-stop solution for all
              your billing needs.
            </b>
          </p>
          <div className="row">
            <div className="col-md-6 col-12">
              <img
                className="w-100"
                alt="Billpunch"
                src={require("../../../assets/images/Billpunch_aboutus.png")}
              />
            </div>
            <div className="col-md-6 col-12 mt-md-5 mt-2">
              <h4 style={{ color: "RGB(93,136,228)" }}>
                <b>Who We Are?</b>
              </h4>
              <p>
                BillPunch is a cutting-edge platform designed to help you take
                control of your finances. Founded by a team of passionate
                individuals with extensive experience in financial technology
                and customer service, we are dedicated to making office
                management easy, efficient, and accessible to everyone.
              </p>
              <p>
                <span style={{ fontSize: "1.1rem" }}>
                  <b>Our vision</b>
                </span>{" "}
                is to transform the way people manage their bills by offering
                innovative, user-friendly solutions. We aim to be the leading
                platform for office management, helping individuals and
                businesses save time, reduce stress, and stay on top of their
                finances.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 mt-md-5 order-md-first order-last mt-2 ">
              <h4 style={{ color: "RGB(93,136,228)" }}>
                <b>Why Choose BillPunch? </b>
              </h4>
              <p>
                Billpunch handles everything from tracking employee attendance
                and managing leave to processing payroll, tracking expenses, and
                managing assets. Our software reduces mistakes, ensures
                compliance, and increases productivity. We’re committed to using
                technology to create smarter workplaces, and our team is always
                working to meet the changing needs of our clients.
              </p>
            </div>
            <div className="col-md-6 col-12">
              <img
                className="w-100"
                alt="Billpunch"
                src={require("../../../assets/images/about1.png")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <img
                className="w-100"
                alt="Billpunch"
                src={require("../../../assets/images/about3.png")}
              />
            </div>
            <div className="col-md-6 col-12 mt-md-5 mt-2">
              <h4 style={{ color: "RGB(93,136,228)" }}>
                <b>Join Us Today</b>
              </h4>
              <p>
                Take the first step towards hassle-free bill management with
                BillPunch. Join our growing community of satisfied users and
                experience the difference. Sign up today and see how BillPunch
                can simplify your life!
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Billpunchaboutus;
