import React, { useContext, useEffect, useState } from "react";
import { FaCalendarAlt, FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { FcSalesPerformance } from "react-icons/fc";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { billpunchbasicauth } from "../../utils/Credentials";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Bar, Line, Pie } from "react-chartjs-2";
import Select from "react-select";
import swal from "sweetalert";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { IoIosAlert } from "react-icons/io";
const Perfomance = () => {
  const [activeContent, setActiveContent] = useState("Dashboard");
  const renderContent = () => {
    switch (activeContent) {
      case "Dashboard":
        return <Dashboard />;
      case "Technical":
        return <Details />;
      case "Mcq":
        return <Mcq />;
      case "Self Review ":
        return <Selfreview />;
      case "Firm Review ":
        return <Firmreview />;
      default:
        return <p>Select an option from the sidebar.</p>;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <aside
        className="pt-2"
        style={{
          width: "9rem",
          color: "#000",
          boxShadow: "2px 0px 5px rgba(0,0,0,0.1)",
        }}
      >
        <ul
          style={{
            listStyle: "none",
            padding: 0,
          }}
        >
          {[
            { name: "Dashboard", icon: <FcSalesPerformance /> },
            { name: "Mcq", icon: <FaCalendarAlt /> },
            { name: "Technical", icon: <FaCalendarAlt /> },
            { name: "Self Review ", icon: <FaCalendarAlt /> },
            { name: "Firm Review ", icon: <FaCalendarAlt /> },
          ].map((item) => (
            <li
              key={item.name}
              className={`sidebar-${item.name
                .toLowerCase()
                .replace(/\s+/g, "-")}`}
              style={{
                fontWeight: activeContent === item.name ? "bold" : "normal",
                backgroundColor:
                  activeContent === item.name ? "#eeeeee" : "transparent",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => setActiveContent(item.name)}
            >
              {item.icon} {item.name}
            </li>
          ))}
        </ul>
      </aside>

      <main
        style={{
          flex: 1,
          height: "100vh",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          scrollbarWidth: "none",
          WebkitOverflowScrolling: "touch",
        }}
        // className="mt-3"
      >
        {renderContent()}
      </main>
    </div>
  );
};
const Dashboard = () => {
  const performanceData = {
    labels: [
      "Technical Skills",
      "Problem-Solving",
      "Teamwork",
      "Innovation",
      "Punctuality",
    ],
    datasets: [
      {
        label: "Performance Score",
        data: [85, 90, 75, 80, 95],
        backgroundColor: "#82ca9d",
      },
    ],
  };
  const [punctualityData, setPunctualityData] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Punctuality",
        data: Array(12).fill(0),
        fill: false,
        borderColor: "#42A5F5",
        tension: 0.4,
      },
    ],
  });

  const ratingBreakdown = {
    labels: ["Excellent", "Good", "Average", "Needs Improvement"],
    datasets: [
      {
        data: [40, 35, 15, 10],
        backgroundColor: ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"],
      },
    ],
  };
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [projects, setProjects] = useState([]);
  const [Projectdata, setProjectdata] = useState([]);
  const [financialYear, setFinancialYear] = useState(null);
  const [quarterlyYear, setQuarterlyYear] = useState(null);
  const financialYearOptions = generateFinancialYearOptions();
  const quarterlyYearOptions = generateQuarterlyYearOptions();
  const [showInputs, setShowInputs] = useState(false);
  const handleButtonClick = () => {
    if (financialYear && quarterlyYear) {
      setShowInputs(true);
    }
  };

  const GetAttendancePercentage = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAttendancePercentage`,
        requestOptions
      );
      const responseData = await res.json();

      if (responseData.status === "0" && responseData.data.length > 0) {
        const attendance = responseData.data[0];
        const attendanceValues = [
          attendance.jan2025 ?? 0,
          attendance.feb2025 ?? 0,
          attendance.mar2024 ?? 0,
          attendance.apr2024 ?? 0,
          attendance.may2024 ?? 0,
          attendance.jun2024 ?? 0,
          attendance.jul2024 ?? 0,
          attendance.aug2024 ?? 0,
          attendance.sep2024 ?? 0,
          attendance.oct2024 ?? 0,
          attendance.nov2024 ?? 0,
          attendance.dec2024 ?? 0,
        ];

        setPunctualityData((prev) => ({
          ...prev,
          datasets: [
            {
              ...prev.datasets[0],
              data: attendanceValues,
            },
          ],
        }));
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      setTransactionLoaderState(false);
    }
  };
  const GetProjectOwner = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetProjectOwner`,
        requestOptions
      );
      const result = await res.json();
      if (result.status === "0") {
        setProjects(result.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      setTransactionLoaderState(false);
    }
  };
  const fetchproject = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: user.userid,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetProjectByEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const uniqueProjectIds = [
          ...new Set(data.data.map((project) => project.pk_project_id)),
        ];
        setProjectdata(data.data);
        GetProjectAnalytics(uniqueProjectIds); // Pass the extracted project IDs
      }
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const GetProjectAnalytics = async (projectIds) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      // Loop through each project ID and fetch analytics
      const analyticsData = await Promise.all(
        projectIds.map(async (projectId) => {
          var raw = JSON.stringify({
            empid: user.userid,
            firmid: user.firm_id,
            projectid: projectId,
          });

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          const res = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetProjectAnalytics`,
            requestOptions
          );
          return res.json();
        })
      );

      // Extract only the successful responses
      const validData = analyticsData
        .filter((item) => item.status === "0")
        .flatMap((item) => item.data);

      setProjectdata(validData);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    GetAttendancePercentage();
    GetProjectOwner();
    fetchproject();
  }, []);
  return (
    <Container fluid className="mt-2">
      <Row>
        <div className="col-3 pr-0">
          {/* <label>Financial Year</label> */}
          <Select
            options={financialYearOptions}
            value={financialYear}
            onChange={setFinancialYear}
            placeholder="Select Financial Year"
          />
        </div>
        <div className="col-3">
          {/* <label>Quarterly Year</label> */}
          <Select
            options={quarterlyYearOptions}
            value={quarterlyYear}
            onChange={setQuarterlyYear}
            placeholder="Select Quarterly Year"
          />
        </div>

        <div>
          {" "}
          <Button size="sm" variant="primary" onClick={handleButtonClick}>
            Search
          </Button>
        </div>
      </Row>
      <Row>
        <Col md={6} className="pr-0">
          <Card className="p-3 shadow">
            <h5>Monthly Attendance</h5>
            <Line data={punctualityData} />
          </Card>
        </Col>
        <Col md={6} className="pr-0">
          <Card className="p-3 shadow">
            <h5>Performance Metrics</h5>
            <Bar data={performanceData} />
          </Card>
        </Col>
      </Row>

      <Row className="">
        <Col md={6}>
          <Card
            className="p-2"
            style={{
              height: "45vh",
              overflowY: "scroll",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <h6 className="text-center">Project Details</h6>
            {Projectdata.length > 0 ? (
              Projectdata.map((project) => (
                <div key={project.pk_project_id} className="mb-2">
                  <h6>{project.project_name}</h6>
                  <Row className="p-0 m-0">
                    {" "}
                    <p className="col-8 m-0 p-0">
                      <strong>Owner:</strong> {project.project_ownership}
                    </p>
                    <p className="col-4 m-0 p-0 ">
                      <strong>Start Date:</strong>{" "}
                      {new Date(
                        project.project_startdate
                      ).toLocaleDateString() || "NA"}{" "}
                    </p>
                  </Row>
                  <Row className="p-0 m-0">
                    {" "}
                    <p className="col-8 m-0 p-0">
                    <strong><IoCheckmarkDoneCircleSharp style={{color:"green", fontSize:"1rem"}}/> Completed On Time:</strong>{" "}
                    {project.completedOntime}&nbsp;Task
                    </p>
                    <p className="col-4 m-0 p-0 ">
                    <strong> <IoIosAlert style={{color:"red", fontSize:"1rem"}}/>Overdue:</strong> {project.overdue}
                    </p>
                  </Row>
                 
                  <hr className="m-2" />
                </div>
              ))
            ) : (
              <p className="text-center">No project data available</p>
            )}
          </Card>
        </Col>

        <Col md={6}>
          <Card
            className="p-2"
            style={{
              height: "45vh",
              overflowY: "scroll",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <h6 className="text-center">Project Details</h6>
            {projects.map((project) => (
              <div key={project.pk_project_id}>
                <h5 className="mb-2">{project.project_name}</h5>

                <Row className="p-0 m-0">
                  {" "}
                  <p className="col-8 m-0 p-0">
                    <strong>Owner:</strong> {project.project_ownership}
                  </p>
                  <p className="col-4 m-0 p-0 ">
                    <strong>Start Date:</strong>{" "}
                    {new Date(project.project_startdate).toLocaleDateString() ||
                      "NA"}{" "}
                  </p>
                </Row>
                {/* <p><strong>End Date:</strong> {project.project_enddate}</p> */}
                <p className="">
                  <strong>Description:</strong> {project.project_discription}
                </p>
                <hr className="m-1" />
              </div>
            ))}
          </Card>
        </Col>
      </Row>
      <Col md={6} className="pr-0">
        <Card className="p-2" style={{ height: "40vh" }}>
          <h5 style={{ flexShrink: 0 }}>Performance Ratings Breakdown</h5>
          <Pie data={ratingBreakdown} />
        </Card>
      </Col>
      {/* <Row className="">
        <Col>
          <Card className="p-3 shadow">
            <h5>Skills & Competency Assessment</h5>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td>Technical Skills</td>
                  <td>⭐⭐⭐⭐☆</td>
                </tr>
                <tr>
                  <td>Soft Skills</td>
                  <td>⭐⭐⭐⭐☆</td>
                </tr>
                <tr>
                  <td>Problem-Solving</td>
                  <td>⭐⭐⭐⭐⭐</td>
                </tr>
                <tr>
                  <td>Innovation</td>
                  <td>⭐⭐⭐⭐⭐</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row> */}
    </Container>
  );
};
const generateFinancialYearOptions = () => {
  const startYear = 2024;
  const years = [];
  for (let i = startYear; i < startYear + 5; i++) {
    years.push({
      value: `${i}-${i + 1}`,
      label: `${i}-${i + 1}`,
    });
  }
  return years;
};
const generateQuarterlyYearOptions = () => {
  const quarters = [
    { value: "1", label: "Q1 (April - June)" },
    { value: "2", label: "Q2 (July - September)" },
    { value: "3", label: "Q3 (October - December)" },
    { value: "4", label: "Q4 (January - March)" },
  ];
  return quarters;
};
const Mcq = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [mcqData, setMcqData] = useState([]);
  const [financialYear, setFinancialYear] = useState(null);
  const [quarterlyYear, setQuarterlyYear] = useState(null);
  const [showInputs, setShowInputs] = useState(false);
  const decodeText = (text) => {
    return decodeURIComponent(text.replace(/â€™/g, "’"));
  };
  const GetAppraisalMCQ = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          techid: user.techid,
          quarter: quarterlyYear.value,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAppraisalMCQ`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data) {
        setMcqData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      setTransactionLoaderState(false);
    }
  };
  const financialYearOptions = generateFinancialYearOptions();
  const quarterlyYearOptions = generateQuarterlyYearOptions();
  const [selectedAnswers, setSelectedAnswers] = useState({});

  const handleOptionChange = (questionId, selectedOption) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: selectedOption,
    }));
  };
  const handleButtonClick = () => {
    if (financialYear && quarterlyYear) {
      setShowInputs(true);
      GetAppraisalMCQ();
      GetAllQuestionAnswer();
    }
  };
  const InsertAppraisalAnswer = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requests = Object.keys(selectedAnswers).map((questionId) => ({
        questionid: questionId,
        ans: selectedAnswers[questionId],
      }));

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          type: "Tech",
          empid: user.userid,
          requests: requests,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/InsertAppraisalAnswer`,
        requestOptions
      );
      const responseData = await res.json();

      if (
        responseData.status === "0" &&
        responseData.result[0]?.result === "Y"
      ) {
        swal("Success", responseData.result[0].message, "success");
      } else {
        swal("Error", "Failed to save answers. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
      swal("Error", "Something went wrong. Please try again.", "error");
    }
    setTransactionLoaderState(false);
  };
  const GetAllQuestionAnswer = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          type: "Tech",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllQuestionAnswer`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data) {
        const answers = {};
        data.data.forEach((item) => {
          answers[item.question_id] = item.selected_ans;
        });
        setSelectedAnswers(answers);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ answers:", error);
      setTransactionLoaderState(false);
    }
  };

  return (
    <div className="m-3">
      <h5 className="mb-0">Technical Evaluation</h5>
      <Row>
        <div className="col-3 pr-0">
          <Select
            options={financialYearOptions}
            value={financialYear}
            onChange={setFinancialYear}
            placeholder="Select Financial Year"
          />
        </div>
        <div className="col-3">
          <Select
            options={quarterlyYearOptions}
            value={quarterlyYear}
            onChange={setQuarterlyYear}
            placeholder="Select Quarterly Year"
          />
        </div>

        <div className="col d-flex" style={{ justifyContent: "space-between" }}>
          <Button size="sm" variant="primary" onClick={handleButtonClick}>
            Search
          </Button>
          <Button
            size="sm"
            variant="success"
            className="mt-3"
            onClick={InsertAppraisalAnswer}
          >
            Submit
          </Button>
        </div>
      </Row>

      {showInputs && (
        <div className="">
          <div>
            {mcqData.length > 0 ? (
              mcqData.map((question, index) =>
                index % 2 === 0 ? (
                  <div className="row pb-0 pt-0" key={question.id}>
                    {/* First Question */}
                    <div className="col-md-6 p-2">
                      <label
                        className="form-label"
                        style={{ fontSize: ".9rem" }}
                      >
                        {decodeText(question.question)}
                      </label>
                      <div>
                        {["A", "B", "C", "D"].map((option, i) => (
                          <div key={i} style={{ fontSize: ".9rem" }}>
                            <input
                              type="radio"
                              name={`q${question.id}`}
                              value={option}
                              onChange={() =>
                                handleOptionChange(question.id, option)
                              }
                              checked={selectedAnswers[question.id] === option}
                            />{" "}
                            {question[`option_${option.toLowerCase()}`]}
                          </div>
                        ))}
                      </div>
                    </div>

                    {mcqData[index + 1] && (
                      <div className="col-md-6 p-2">
                        <label
                          className="form-label"
                          style={{ fontSize: ".9rem" }}
                        >
                          {decodeText(mcqData[index + 1].question)}
                        </label>
                        <div>
                          {["A", "B", "C", "D"].map((option, i) => (
                            <div key={i} style={{ fontSize: ".9rem" }}>
                              <input
                                type="radio"
                                name={`q${mcqData[index + 1].id}`}
                                value={option}
                                onChange={() =>
                                  handleOptionChange(
                                    mcqData[index + 1].id,
                                    option
                                  )
                                }
                                checked={
                                  selectedAnswers[mcqData[index + 1].id] ===
                                  option
                                }
                              />{" "}
                              {
                                mcqData[index + 1][
                                  `option_${option.toLowerCase()}`
                                ]
                              }
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ) : null
              )
            ) : (
              <p>No MCQs available.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
const Selfreview = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [mcqData, setMcqData] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const decodeText = (text) => {
    return decodeURIComponent(text.replace(/â€™/g, "’"));
  };
  const GetAppraisalMCQSelf = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAppraisalMCQSelf`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data) {
        setMcqData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      setTransactionLoaderState(false);
    }
  };
  const handleOptionChange = (questionId, value) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };
  const InsertAppraisalAnswer = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      const requests = Object.keys(selectedAnswers).map((questionId) => ({
        questionid: questionId,
        ans: selectedAnswers[questionId],
      }));

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          type: "Self",
          empid: user.userid,
          requests: requests,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/InsertAppraisalAnswer`,
        requestOptions
      );
      const responseData = await res.json();

      if (
        responseData.status === "0" &&
        responseData.result[0]?.result === "Y"
      ) {
        swal("Success", responseData.result[0].message, "success");
      } else {
        swal("Error", "Failed to save answers. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
      swal("Error", "Something went wrong. Please try again.", "error");
    }
    setTransactionLoaderState(false);
  };
  const GetAllQuestionAnswer = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          type: "Self",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllQuestionAnswer`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data) {
        const answers = {};
        data.data.forEach((item) => {
          answers[item.question_id] = item.selected_ans;
        });
        setSelectedAnswers(answers);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ answers:", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    GetAppraisalMCQSelf();
    GetAllQuestionAnswer();
  }, []);

  return (
    <div className="m-3">
      <h5 className="mb-0">Self Evaluation</h5>
      <div className="">
        {mcqData.length > 0 ? (
          mcqData.map((question, index) =>
            index % 2 === 0 ? (
              <div className="row pb-0 pt-0" key={question.question_id}>
                {/* First Question */}
                <div className="col-md-6 p-2">
                  <label className="form-label" style={{ fontSize: ".9rem" }}>
                    {decodeText(question.question_text)}
                  </label>
                  <div>
                    {["a", "b", "c", "d"].map((option, i) => (
                      <div key={i} style={{ fontSize: ".9rem" }}>
                        <input
                          type="radio"
                          name={`q${question.question_id}`}
                          value={option}
                          onChange={() =>
                            handleOptionChange(question.question_id, option)
                          }
                          checked={
                            selectedAnswers[question.question_id] === option
                          }
                        />{" "}
                        {
                          question[
                            `option_${
                              ["poor", "good", "very_good", "excellent"][i]
                            }`
                          ]
                        }
                      </div>
                    ))}
                  </div>
                </div>

                {/* Second Question (if exists) */}
                {mcqData[index + 1] && (
                  <div className="col-md-6 p-2">
                    <label className="form-label" style={{ fontSize: ".9rem" }}>
                      {decodeText(mcqData[index + 1].question_text)}
                    </label>
                    <div>
                      {["a", "b", "c", "d"].map((option, i) => (
                        <div key={i} style={{ fontSize: ".9rem" }}>
                          <input
                            type="radio"
                            name={`q${mcqData[index + 1].question_id}`}
                            value={option}
                            onChange={() =>
                              handleOptionChange(
                                mcqData[index + 1].question_id,
                                option
                              )
                            }
                            checked={
                              selectedAnswers[
                                mcqData[index + 1].question_id
                              ] === option
                            }
                          />{" "}
                          {
                            mcqData[index + 1][
                              `option_${
                                ["poor", "good", "very_good", "excellent"][i]
                              }`
                            ]
                          }
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : null
          )
        ) : (
          <p>No MCQs available.</p>
        )}
      </div>
      {/* Submit Button */}
      <button className="btn btn-primary mt-3" onClick={InsertAppraisalAnswer}>
        Submit
      </button>
    </div>
  );
};
const Firmreview = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [mcqData, setMcqData] = useState([]);
  const [answers, setAnswers] = useState({});
  const decodeText = (text) => {
    return decodeURIComponent(text.replace(/â€™/g, "’"));
  };
  const GetAppraisalCompany = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAppraisalCompany`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data) {
        setMcqData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      setTransactionLoaderState(false);
    }
  };
  const GetAllQuestionAnswer = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          type: "Firm",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllQuestionAnswer`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data) {
        const formattedAnswers = data.data.reduce((acc, item) => {
          const answersArray = item.selected_ans
            ? item.selected_ans.split(/\d+\)\s+/).filter(Boolean)
            : [];

          return { ...acc, [item.question_id]: answersArray };
        }, {});

        setAnswers(formattedAnswers);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      setTransactionLoaderState(false);
    }
  };

  const InsertAppraisalAnswer = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      const formattedAnswers = Object.keys(answers).map((questionId) => ({
        questionid: questionId,
        ans: answers[questionId]
          .map((ans, index) => `${index + 1}) ${ans}`)
          .join(" "),
      }));

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          type: "Firm",
          empid: user.userid,
          requests: formattedAnswers,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/InsertAppraisalAnswer`,
        requestOptions
      );
      const responseData = await res.json();

      if (
        responseData.status === "0" &&
        responseData.result[0]?.result === "Y"
      ) {
        swal("Success", responseData.result[0].message, "success");
      } else {
        swal("Error", "Failed to save answers. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
      swal("Error", "Something went wrong. Please try again.", "error");
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    GetAppraisalCompany();
    GetAllQuestionAnswer();
  }, []);

  const handleAddInput = (questionId) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: [...(prev[questionId] || []), ""],
    }));
  };

  const handleRemoveInput = (questionId, index) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: prev[questionId].filter((_, i) => i !== index),
    }));
  };

  const handleInputChange = (questionId, index, value) => {
    const updatedAnswers = [...(answers[questionId] || [])];
    updatedAnswers[index] = value;
    setAnswers((prev) => ({
      ...prev,
      [questionId]: updatedAnswers,
    }));
  };

  return (
    <div className="m-3">
      <h5 className="mb-0">Firm Review</h5>
      <div className="">
        {mcqData.length > 0 ? (
          mcqData.map((question, index) => {
            if (index % 2 === 0) {
              return (
                <div className="row pb-0 pt-0" key={question.question_id}>
                  <div className="col-md-6 p-2">
                    <label className="form-label" style={{ fontSize: ".9rem" }}>
                      {decodeText(question.question_text)}
                    </label>
                    <div>
                      {answers[question.question_id]?.map(
                        (answer, answerIndex) => (
                          <div className="input-group mb-2" key={answerIndex}>
                            <input
                              className="form-control"
                              placeholder="Your answer..."
                              value={answer}
                              onChange={(e) =>
                                handleInputChange(
                                  question.question_id,
                                  answerIndex,
                                  e.target.value
                                )
                              }
                            />
                            <span
                              className=" px-2 mt-1"
                              onClick={() =>
                                handleRemoveInput(
                                  question.question_id,
                                  answerIndex
                                )
                              }
                            >
                              <FaMinusCircle />
                            </span>
                          </div>
                        )
                      )}
                      <span
                        className=" mt-2"
                        onClick={() => handleAddInput(question.question_id)}
                      >
                        <FaPlusCircle />
                      </span>
                    </div>
                  </div>

                  {mcqData[index + 1] && (
                    <div className="col-md-6 p-2">
                      <label
                        className="form-label"
                        style={{ fontSize: ".9rem" }}
                      >
                        {decodeText(mcqData[index + 1].question_text)}
                      </label>
                      <div>
                        {answers[mcqData[index + 1].question_id]?.map(
                          (answer, answerIndex) => (
                            <div className="input-group mb-2" key={answerIndex}>
                              <input
                                className="form-control"
                                placeholder="Your answer..."
                                value={answer}
                                onChange={(e) =>
                                  handleInputChange(
                                    mcqData[index + 1].question_id,
                                    answerIndex,
                                    e.target.value
                                  )
                                }
                              />
                              <span
                                className="px-2  mt-1"
                                onClick={() =>
                                  handleRemoveInput(
                                    mcqData[index + 1].question_id,
                                    answerIndex
                                  )
                                }
                              >
                                <FaMinusCircle />
                              </span>
                            </div>
                          )
                        )}
                        <span
                          className=" mt-2"
                          onClick={() =>
                            handleAddInput(mcqData[index + 1].question_id)
                          }
                        >
                          <FaPlusCircle />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
            return null;
          })
        ) : (
          <p>No questions available.</p>
        )}
      </div>
      <button className="btn btn-primary mt-3" onClick={InsertAppraisalAnswer}>
        Submit Answers
      </button>
    </div>
  );
};
const Details = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [mcqData, setMcqData] = useState([]);
  const [answers, setAnswers] = useState({});
  const decodeText = (text) => {
    return decodeURIComponent(text.replace(/â€™/g, "’"));
  };
  const GetAppraisalCompany = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          techid: user.techid,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAppraisalQuestion`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data) {
        setMcqData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      setTransactionLoaderState(false);
    }
  };
  const GetAllQuestionAnswer = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: user.userid,
          type: "Tech1",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllQuestionAnswer`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data) {
        const formattedAnswers = data.data.reduce((acc, item) => {
          const answersArray = item.selected_ans
            ? item.selected_ans.split(/\d+\)\s+/).filter(Boolean)
            : [];

          return { ...acc, [item.question_id]: answersArray };
        }, {});

        setAnswers(formattedAnswers);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      setTransactionLoaderState(false);
    }
  };

  const Handlesubmit = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const formattedAnswers = Object.keys(answers).map((questionId) => ({
        questionid: questionId,
        ans: answers[questionId]
          .map((ans, index) => `${index + 1}) ${ans}`)
          .join(" "),
      }));

      console.log("Submitting data:", formattedAnswers);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          type: "Tech1",
          empid: user.userid,
          requests: formattedAnswers,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/InsertAppraisalAnswer`,
        requestOptions
      );

      const responseData = await res.json();
      console.log("API Response:", responseData);

      if (
        responseData.status === "0" &&
        responseData.result[0]?.result === "Y"
      ) {
        swal("Success", responseData.result[0].message, "success");
      } else {
        swal("Error", "Failed to save answers. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
      swal("Error", "Something went wrong. Please try again.", "error");
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    GetAppraisalCompany();
    GetAllQuestionAnswer();
  }, []);

  const handleAddInput = (questionId) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: [...(prev[questionId] || []), ""],
    }));
  };

  const handleRemoveInput = (questionId, index) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: prev[questionId].filter((_, i) => i !== index),
    }));
  };

  const handleInputChange = (questionId, index, value) => {
    const updatedAnswers = [...(answers[questionId] || [])];
    updatedAnswers[index] = value;
    setAnswers((prev) => ({
      ...prev,
      [questionId]: updatedAnswers,
    }));
  };

  return (
    <div className="m-3">
      <h5 className="mb-0">Firm Review</h5>
      <div className="">
        {mcqData.length > 0 ? (
          mcqData.map((question, index) => {
            if (index % 2 === 0) {
              return (
                <div className="row pb-0 pt-0" key={question.question_id}>
                  <div className="col-md-6 p-2">
                    <label className="form-label" style={{ fontSize: ".9rem" }}>
                      {decodeText(question.question_text)}
                    </label>
                    <div>
                      {answers[question.question_id]?.map(
                        (answer, answerIndex) => (
                          <div className="input-group mb-2" key={answerIndex}>
                            <input
                              className="form-control"
                              placeholder="Your answer..."
                              value={answer}
                              onChange={(e) =>
                                handleInputChange(
                                  question.question_id,
                                  answerIndex,
                                  e.target.value
                                )
                              }
                            />
                            <span
                              className=" px-2 mt-1"
                              onClick={() =>
                                handleRemoveInput(
                                  question.question_id,
                                  answerIndex
                                )
                              }
                            >
                              <FaMinusCircle />
                            </span>
                          </div>
                        )
                      )}
                      <span
                        className=" mt-2"
                        onClick={() => handleAddInput(question.question_id)}
                      >
                        <FaPlusCircle />
                      </span>
                    </div>
                  </div>

                  {mcqData[index + 1] && (
                    <div className="col-md-6 p-2">
                      <label
                        className="form-label"
                        style={{ fontSize: ".9rem" }}
                      >
                        {decodeText(mcqData[index + 1].question_text)}
                      </label>
                      <div>
                        {answers[mcqData[index + 1].question_id]?.map(
                          (answer, answerIndex) => (
                            <div className="input-group mb-2" key={answerIndex}>
                              <input
                                className="form-control"
                                placeholder="Your answer..."
                                value={answer}
                                onChange={(e) =>
                                  handleInputChange(
                                    mcqData[index + 1].question_id,
                                    answerIndex,
                                    e.target.value
                                  )
                                }
                              />
                              <span
                                className="px-2  mt-1"
                                onClick={() =>
                                  handleRemoveInput(
                                    mcqData[index + 1].question_id,
                                    answerIndex
                                  )
                                }
                              >
                                <FaMinusCircle />
                              </span>
                            </div>
                          )
                        )}
                        <span
                          className=" mt-2"
                          onClick={() =>
                            handleAddInput(mcqData[index + 1].question_id)
                          }
                        >
                          <FaPlusCircle />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
            return null;
          })
        ) : (
          <p>No questions available.</p>
        )}
      </div>
      <button className="btn btn-primary mt-3" onClick={Handlesubmit}>
        Submit Answers
      </button>
    </div>
  );
};
export default Perfomance;
