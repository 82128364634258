import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./footer";

const Expensemanagement = () => {
  const [pageData, setPageData] = useState(null);
 
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();
      
      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];
  
        console.log("Pages Data:", pages); 
  
        const faqPageData = pages.find(page => page.page_id === "12");
  
        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };

  useEffect(() => {
    fetchSeoData(); 
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.innerHTML = `
      document.addEventListener("DOMContentLoaded", function() {
          console.log("Running SEO script...");

          const seoUrl = "https://seobix.com/integrations/meta_seo.php";
          const requestBody = JSON.stringify({
              weburl: "https://billpunch.com",
              fileurl: "https://billpunch.com/SeoSettings_638749552913980001.enc"
          });

          fetch(seoUrl, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: requestBody
          })
          .then(response => response.json())
          .then(seoData => {
              console.log("SEO Data:", seoData);

              if (seoData.status !== "1" || !seoData.data || seoData.data.length === 0) {
                  console.error("Invalid SEO response format");
                  return;
              }

               const currentUrl = window.location.href.replace(/\/$/, '').replace(/^https?:\/\/www\./, 'https://');
            console.log(currentUrl);
            const page = seoData.data[0].pages.find(p => p.page_url === currentUrl);
            if (!page) {
                console.error(currentUrl+"Page not found");
                return;
            }

              const { basic_settings, og_settings, keyword_settings } = page;

              if (basic_settings.tittle) document.title = basic_settings.tittle;

              const metaTags = {
                  "description": basic_settings.meta_desc,
                  "keywords": keyword_settings.map(k => k.keyword).join(", "),
                  "og:title": og_settings.og_title,
                  "og:description": og_settings.og_desc,
                  "og:image": og_settings.og_image,
                  "og:url": og_settings.og_url,
                  "og:type": og_settings.og_type,
                  "og:site_name": og_settings.og_sitename,
                  "twitter:title": og_settings.twitter_title,
                  "twitter:description": og_settings.twitter_desc,
                  "twitter:image": og_settings.twitter_image
              };

              Object.entries(metaTags).forEach(([key, value]) => {
                  if (value) {
                      let metaTag = document.querySelector(\`meta[name="\${key}"], meta[property="\${key}"]\`);
                      if (!metaTag) {
                          metaTag = document.createElement("meta");
                          if (key.startsWith("og:") || key.startsWith("twitter:")) {
                              metaTag.setAttribute("property", key);
                          } else {
                              metaTag.setAttribute("name", key);
                          }
                          document.head.appendChild(metaTag);
                      }
                      metaTag.setAttribute("content", value);
                  }
              });
          })
          .catch(error => console.error("SEO Script Error:", error));
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
            
      <Navbar />
      <h1 className="text-center mt-3">Expense Management </h1>
      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-8">
          <p>Efficient expense management is crucial for maintaining financial health and employee satisfaction in any organization. BillPunch's Human Resource Management System (HRMS) offers a comprehensive expense management module designed to streamline the process, ensuring transparency, accuracy, and ease of use for both employees and administrators.</p>

          <h5>Key Features of BillPunch's Expense Management:</h5>

          <h5>1. User-Friendly Interface:</h5>
          <ul>
            <li><strong>Intuitive Design:</strong> Easy-to-navigate interface that simplifies the submission and approval process.</li>
            <li><strong>Mobile Access:</strong> Employees can submit and track expenses on the go using their mobile devices.</li>
          </ul>

          <h5>2. Expense Submission:</h5>
          <ul>
            <li><strong>Multi-Format Receipt Uploads:</strong> Supports various file formats for receipt uploads, including photos and PDFs.</li>
            <li><strong>Categorization:</strong> Users can categorize expenses (e.g., travel, meals, office supplies) for better tracking and reporting.</li>
          </ul>

          <h5>3. Approval Workflow:</h5>
          <ul>
            <li><strong>Customizable Approval Chains:</strong> Define multiple levels of approvals based on expense type, amount, or department.</li>
            <li><strong>Real-Time Notifications:</strong> Automatic alerts for managers and approvers to review and approve submissions promptly.</li>
          </ul>

          <h5>4. Policy Compliance:</h5>
          <ul>
            <li><strong>Automated Policy Enforcement:</strong> Ensure compliance with company policies by setting rules and limits on various expense categories.</li>
            <li><strong>Policy Violation Alerts:</strong> Notify users and approvers of any policy breaches during the submission process.</li>
          </ul>

          <h5>5. Expense Tracking and Reporting:</h5>
          <ul>
            <li><strong>Detailed Reporting:</strong> Generate comprehensive reports on expense trends, categories, and department-wise expenditures.</li>
            <li><strong>Analytics:</strong> Use data analytics to identify spending patterns and opportunities for cost savings.</li>
          </ul>

          <h5>6. Integration Capabilities:</h5>
          <ul>
            <li><strong>ERP Integration:</strong> Seamlessly integrate with existing ERP systems for smooth data transfer and financial reconciliation.</li>
            <li><strong>Bank Integration:</strong> Direct integration with banking systems for efficient processing of reimbursements.</li>
          </ul>

          <h5>7. Security and Compliance:</h5>
          <ul>
            <li><strong>Data Security:</strong> Ensure the highest level of security with data encryption and secure access protocols.</li>
            <li><strong>Audit Trails:</strong> Maintain a detailed log of all expense-related activities for auditing purposes.</li>
          </ul>

          <h5>8. Employee Support:</h5>
          <ul>
            <li><strong>Help Desk Integration:</strong> Access to support for any issues or queries related to expense submissions and approvals.</li>
            <li><strong>Training Resources:</strong> Comprehensive guides and tutorials to help employees navigate the expense management system effectively.</li>
          </ul>

          <h5>Benefits of Using BillPunch's Expense Management:</h5>
          <ul>
            <li><strong>Efficiency:</strong> Automate repetitive tasks and reduce manual errors, saving time for both employees and finance teams.</li>
            <li><strong>Transparency:</strong> Clear visibility into expense claims and reimbursements, fostering trust and accountability.</li>
            <li><strong>Cost Control:</strong> Better control over expenses with real-time tracking and policy enforcement, leading to significant cost savings.</li>
            <li><strong>Employee Satisfaction:</strong> Quick and accurate reimbursements contribute to higher employee morale and satisfaction.</li>
          </ul>

          <p>By leveraging BillPunch's HRMS for expense management, organizations can not only streamline their expense processes but also ensure compliance, enhance financial control, and improve overall operational efficiency.</p>
        </div>
        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Expensemanagement;
