import React, { useContext, useEffect, useState } from "react";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Bar, Line, Pie } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { IoIosAlert } from "react-icons/io";
import { billpunchbasicauth } from "../../utils/Credentials";
const Perfomanceevaluation = () => {
  const Employeeid = sessionStorage.getItem("currentperEmpId");
  console.log(Employeeid);
  const generateFinancialYearOptions = () => {
    const startYear = 2024;
    const years = [];
    for (let i = startYear; i < startYear + 5; i++) {
      years.push({
        value: `${i}-${i + 1}`,
        label: `${i}-${i + 1}`,
      });
    }
    return years;
  };
  const generateQuarterlyYearOptions = () => {
    const quarters = [
      { value: "Q1", label: "Q1 (April - June)" },
      { value: "Q2", label: "Q2 (July - September)" },
      { value: "Q3", label: "Q3 (October - December)" },
      { value: "Q4", label: "Q4 (January - March)" },
    ];
    return quarters;
  };
  const performanceData = {
    labels: [
      "Technical Skills",
      "Problem-Solving",
      "Teamwork",
      "Innovation",
      "Punctuality",
    ],
    datasets: [
      {
        label: "Performance Score",
        data: [85, 90, 75, 80, 95],
        backgroundColor: "#82ca9d",
      },
    ],
  };
  const [punctualityData, setPunctualityData] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Punctuality",
        data: Array(12).fill(0),
        fill: false,
        borderColor: "#42A5F5",
        tension: 0.4,
      },
    ],
  });

  const ratingBreakdown = {
    labels: ["Excellent", "Good", "Average", "Needs Improvement"],
    datasets: [
      {
        data: [40, 35, 15, 10],
        backgroundColor: ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"],
      },
    ],
  };
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [projects, setProjects] = useState([]);
  const [Projectdata, setProjectdata] = useState([]);
  const [financialYear, setFinancialYear] = useState(null);
  const [quarterlyYear, setQuarterlyYear] = useState(null);
  const financialYearOptions = generateFinancialYearOptions();
  const quarterlyYearOptions = generateQuarterlyYearOptions();
  const [showInputs, setShowInputs] = useState(false);
  const handleButtonClick = () => {
    if (financialYear && quarterlyYear) {
      setShowInputs(true);
    }
  };

  const GetAttendancePercentage = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: Employeeid,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAttendancePercentage`,
        requestOptions
      );
      const responseData = await res.json();

      if (responseData.status === "0" && responseData.data.length > 0) {
        const attendance = responseData.data[0];
        const attendanceValues = [
          attendance.jan2025 ?? 0,
          attendance.feb2025 ?? 0,
          attendance.mar2024 ?? 0,
          attendance.apr2024 ?? 0,
          attendance.may2024 ?? 0,
          attendance.jun2024 ?? 0,
          attendance.jul2024 ?? 0,
          attendance.aug2024 ?? 0,
          attendance.sep2024 ?? 0,
          attendance.oct2024 ?? 0,
          attendance.nov2024 ?? 0,
          attendance.dec2024 ?? 0,
        ];

        setPunctualityData((prev) => ({
          ...prev,
          datasets: [
            {
              ...prev.datasets[0],
              data: attendanceValues,
            },
          ],
        }));
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      setTransactionLoaderState(false);
    }
  };
  const GetProjectOwner = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: Employeeid,
          firmid: user.firm_id,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetProjectOwner`,
        requestOptions
      );
      const result = await res.json();
      if (result.status === "0") {
        setProjects(result.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      setTransactionLoaderState(false);
    }
  };
  const fetchproject = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        empid: Employeeid,
        firmid: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetProjectByEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const uniqueProjectIds = [
          ...new Set(data.data.map((project) => project.pk_project_id)),
        ];
        setProjectdata(data.data);
        GetProjectAnalytics(uniqueProjectIds); // Pass the extracted project IDs
      }
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  const GetProjectAnalytics = async (projectIds) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      // Loop through each project ID and fetch analytics
      const analyticsData = await Promise.all(
        projectIds.map(async (projectId) => {
          var raw = JSON.stringify({
            empid: Employeeid,
            firmid: user.firm_id,
            projectid: projectId,
          });

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          const res = await fetch(
            `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetProjectAnalytics`,
            requestOptions
          );
          return res.json();
        })
      );

      // Extract only the successful responses
      const validData = analyticsData
        .filter((item) => item.status === "0")
        .flatMap((item) => item.data);

      setProjectdata(validData);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };

  useEffect(() => {
    GetAttendancePercentage();
    GetProjectOwner();
    fetchproject();
  }, []);
  const Employeename = JSON.parse(sessionStorage.getItem("currentempnameperformance"));
  console.log(Employeename);
  
  return (
    <Container fluid className="">
      <div className="row" style={{ justifyContent: "space-around" }}>
        <h5 className="col-8 mb-0">{Employeename}</h5>
        <div className="col d-flex justify-content-end">
          <Link to="/retailer/Perfomance_evaluation_List">Back</Link>
        </div>
      </div>
      <Row className="pt-0 ">
        <div className="col-3 pr-0">
          {/* <label>Financial Year</label> */}
          <Select
            options={financialYearOptions}
            value={financialYear}
            onChange={setFinancialYear}
            placeholder="Select Financial Year"
          />
        </div>
        <div className="col-3">
          {/* <label>Quarterly Year</label> */}
          <Select
            options={quarterlyYearOptions}
            value={quarterlyYear}
            onChange={setQuarterlyYear}
            placeholder="Select Quarterly Year"
          />
        </div>

        <div>
          {" "}
          <Button size="sm" variant="primary" onClick={handleButtonClick}>
            Search
          </Button>
        </div>
      </Row>
      <Row>
        <Col md={6} className="pr-0">
          <Card className="p-3 shadow">
            <h5>Monthly Attendance</h5>
            <Line data={punctualityData} />
          </Card>
        </Col>
        <Col md={6} className="pr-0">
          <Card className="p-3 shadow">
            <h5>Performance Metrics</h5>
            <Bar data={performanceData} />
          </Card>
        </Col>
      </Row>
    
      <Row className="">
        <Col md={6}>
          <Card
            className="p-2"
            style={{
              height: "45vh",
              overflowY: "scroll",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <h6 className="text-center">Project Details</h6>
            {Projectdata.length > 0 ? (
              Projectdata.map((project) => (
                <div key={project.pk_project_id} className="mb-2">
                  <h6>{project.project_name}</h6>
                  <Row className="p-0 m-0">
                    {" "}
                    <p className="col-8 m-0 p-0">
                      <strong>Owner:</strong> {project.project_ownership}
                    </p>
                    <p className="col-4 m-0 p-0 ">
                      <strong>Start Date:</strong>{" "}
                      {new Date(
                        project.project_startdate
                      ).toLocaleDateString() || "NA"}{" "}
                    </p>
                  </Row>
                  <Row className="p-0 m-0">
                    {" "}
                    <p className="col-8 m-0 p-0">
                    <strong><IoCheckmarkDoneCircleSharp style={{color:"green", fontSize:"1rem"}}/> Completed On Time:</strong>{" "}
                  {project.completedOntime}&nbsp;Task
                    </p>
                    <p className="col-4 m-0 p-0 ">
                    <strong> <IoIosAlert style={{color:"red", fontSize:"1rem"}}/>Overdue:</strong> {project.overdue}
                    </p>
                  </Row>
                 
                  <hr className="m-2" />
                </div>
              ))
            ) : (
              <p className="text-center">No project data available</p>
            )}
          </Card>
        </Col>

        <Col md={6}>
          <Card
            className="p-2"
            style={{
              height: "45vh",
              overflowY: "scroll",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <h6 className="text-center">Project Details</h6>
            {projects.map((project) => (
              <div key={project.pk_project_id}>
                <h5 className="mb-2">{project.project_name}</h5>

                <Row className="p-0 m-0">
                  {" "}
                  <p className="col-8 m-0 p-0">
                    <strong>Owner:</strong> {project.project_ownership}
                  </p>
                  <p className="col-4 m-0 p-0 ">
                    <strong>Start Date:</strong>{" "}
                    {new Date(project.project_startdate).toLocaleDateString() ||
                      "NA"}{" "}
                  </p>
                </Row>
                {/* <p><strong>End Date:</strong> {project.project_enddate}</p> */}
                <p className="">
                  <strong>Description:</strong> {project.project_discription}
                </p>
                <hr className="m-1" />
              </div>
            ))}
          </Card>
        </Col>
      </Row>
      <Col md={6} className="pr-0">
        <Card className="p-2" style={{ height: "40vh" }}>
          <h5 style={{ flexShrink: 0 }}>Performance Ratings Breakdown</h5>
          <Pie data={ratingBreakdown} />
        </Card>
      </Col>
    </Container>
  );
};

export default Perfomanceevaluation;
