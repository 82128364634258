import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./footer";

const Attendance = () => {
  const [pageData, setPageData] = useState(null);

  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();

      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];

        console.log("Pages Data:", pages);

        const faqPageData = pages.find((page) => page.page_id === "10");

        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.innerHTML = `
      document.addEventListener("DOMContentLoaded", function() {
          console.log("Running SEO script...");

          const seoUrl = "https://seobix.com/integrations/meta_seo.php";
          const requestBody = JSON.stringify({
              weburl: "https://billpunch.com",
              fileurl: "https://billpunch.com/SeoSettings_638749552913980001.enc"
          });

          fetch(seoUrl, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: requestBody
          })
          .then(response => response.json())
          .then(seoData => {
              console.log("SEO Data:", seoData);

              if (seoData.status !== "1" || !seoData.data || seoData.data.length === 0) {
                  console.error("Invalid SEO response format");
                  return;
              }

               const currentUrl = window.location.href.replace(/\/$/, '').replace(/^https?:\/\/www\./, 'https://');
            console.log(currentUrl);
            const page = seoData.data[0].pages.find(p => p.page_url === currentUrl);
            if (!page) {
                console.error(currentUrl+"Page not found");
                return;
            }

              const { basic_settings, og_settings, keyword_settings } = page;

              if (basic_settings.tittle) document.title = basic_settings.tittle;

              const metaTags = {
                  "description": basic_settings.meta_desc,
                  "keywords": keyword_settings.map(k => k.keyword).join(", "),
                  "og:title": og_settings.og_title,
                  "og:description": og_settings.og_desc,
                  "og:image": og_settings.og_image,
                  "og:url": og_settings.og_url,
                  "og:type": og_settings.og_type,
                  "og:site_name": og_settings.og_sitename,
                  "twitter:title": og_settings.twitter_title,
                  "twitter:description": og_settings.twitter_desc,
                  "twitter:image": og_settings.twitter_image
              };

              Object.entries(metaTags).forEach(([key, value]) => {
                  if (value) {
                      let metaTag = document.querySelector(\`meta[name="\${key}"], meta[property="\${key}"]\`);
                      if (!metaTag) {
                          metaTag = document.createElement("meta");
                          if (key.startsWith("og:") || key.startsWith("twitter:")) {
                              metaTag.setAttribute("property", key);
                          } else {
                              metaTag.setAttribute("name", key);
                          }
                          document.head.appendChild(metaTag);
                      }
                      metaTag.setAttribute("content", value);
                  }
              });
          })
          .catch(error => console.error("SEO Script Error:", error));
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      
      <Navbar />
      <h1 className="text-center mt-3">Attendance Management </h1>
      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-8">
          <p>
            In today's fast-paced business environment, efficient attendance
            management is crucial for ensuring productivity and operational
            excellence. BillPunch's Human Resource Management System (HRMS)
            offers a comprehensive Attendance Management module designed to
            streamline and automate the process of tracking employee attendance.
            Our solution integrates seamlessly with various devices and provides
            real-time insights, ensuring accuracy and efficiency.
          </p>

          <h5>1. Automated Time Tracking</h5>
          <ul>
            <li>
              Seamless integration with biometric devices, swipe cards, and
              mobile apps.
            </li>
            <li>Real-time attendance data capture and processing.</li>
            <li>Elimination of manual errors and time theft.</li>
          </ul>

          <h5>2. Flexible Attendance Policies</h5>
          <ul>
            <li>
              Customizable attendance policies to match organizational needs.
            </li>
            <li>
              Support for various shift patterns, including rotational and
              flexible shifts.
            </li>
            <li>
              Automated calculation of late arrivals, early departures, and
              overtime.
            </li>
          </ul>

          <h5>3. Geolocation and Mobile Accessibility</h5>
          <ul>
            <li>Mobile app support for remote and field employees.</li>
            <li>GPS-based attendance marking to ensure location accuracy.</li>
            <li>
              Instant notifications for attendance status and discrepancies.
            </li>
          </ul>

          <h5>4. Leave Management Integration</h5>
          <ul>
            <li>
              Integration with the leave management module for seamless tracking
              of leave balances.
            </li>
            <li>
              Automatic update of attendance records based on approved leaves.
            </li>
            <li>Real-time leave and attendance data synchronization.</li>
          </ul>

          <h5>5. Real-Time Analytics and Reporting</h5>
          <ul>
            <li>
              Comprehensive dashboards displaying attendance trends and
              insights.
            </li>
            <li>
              Customizable reports for detailed analysis of attendance patterns.
            </li>
            <li>
              Automated alerts for irregularities and non-compliance with
              attendance policies.
            </li>
          </ul>

          <h5>6. Compliance and Security</h5>
          <ul>
            <li>
              Adherence to labor laws and organizational attendance policies.
            </li>
            <li>Secure data storage with role-based access control.</li>
            <li>Audit trails for tracking changes in attendance records.</li>
          </ul>

          <h5>Importance</h5>
          <ul>
            <li>
              <strong>Enhanced Productivity:</strong> Accurate tracking of
              working hours ensures fair compensation and boosts employee
              morale. Reduced administrative workload through automation,
              allowing HR teams to focus on strategic tasks.
            </li>
            <li>
              <strong>Improved Accuracy:</strong> Elimination of manual entry
              errors and fraudulent attendance practices. Reliable data for
              payroll processing and performance evaluations.
            </li>
            <li>
              <strong>Increased Transparency:</strong> Clear visibility into
              attendance patterns and employee availability. Transparent
              processes build trust between employees and management.
            </li>
            <li>
              <strong>Cost Savings:</strong> Reduction in payroll errors leading
              to cost savings. Optimized resource allocation and workforce
              planning.
            </li>
            <li>
              <strong>Better Decision Making:</strong> Data-driven insights
              facilitate informed decision-making regarding workforce
              management. Proactive management of absenteeism and overtime helps
              in maintaining optimal staffing levels.
            </li>
          </ul>

          <h5>Conclusion</h5>
          <p>
            BillPunch's Attendance Management module in HRMS is a robust
            solution that simplifies attendance tracking, enhances productivity,
            and ensures compliance. By leveraging advanced technology and
            real-time data, organizations can achieve greater efficiency and
            make informed decisions that drive business success. Implement
            BillPunch's HRMS today and transform your attendance management
            processes for a smarter, more agile workplace.
          </p>
        </div>
        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Attendance;
