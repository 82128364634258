import React, { useState } from "react";
import { Button, Nav, Tab, Tabs } from "react-bootstrap";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "bootstrap/dist/css/bootstrap.min.css";
const Footer = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleInstagramClick = () => {
    window.open(
      "https://www.instagram.com/billpunch_official?igsh=M2gwdWVxZG9idnUy",
      "_blank"
    );
  };
  return (
    <>
      {/* footer */}
      <div className="mt-md-5 mt-3" style={{ background: "#0B3960" }}>
        <div className="row  mx-md-0 mx-3 mt-md-5 mt-sm-3 py-5">
          <div className="col-md-1 "></div>
          <div className="col-md-3 col p-0 ">
            <a href="/">
              <img
                className="mb-3"
                style={{ height: "1.7rem" }}
                src={require("../../../assets/images/billlogo.png")}
                alt="Billpunch"
              />
            </a>

            <a href="/EmployeeManagment">
              <p style={{ color: "#fff" }}>Employee Management</p>
            </a>
            <a href="/Payroll_Management">
              <p style={{ color: "#fff" }}>Payroll Management</p>
            </a>
            <a href="/Attendance_Management">
              <p style={{ color: "#fff" }}>Attendance Management</p>
            </a>
            <a href="/Expense_Management">
              <p style={{ color: "#fff" }}>Expense Management</p>
            </a>
          </div>
          <div className="col mt-md-4 mt-5">
            <h6 classname="mt-1" style={{ color: "#fff" }}>
              {" "}
              Policy
            </h6>
            <a href="/privacy_policy">
              <p style={{ color: "#fff" }}>Privacy Policy</p>
            </a>
            <a href="/refund_policy">
              <p style={{ color: "#fff" }}>Refund & Cancellation</p>
            </a>
          </div>
          <div className="col mt-md-4 mt-5">
            <h6 classname="mt-1" style={{ color: "#fff" }}>
              {" "}
              Resources
            </h6>
            <a href="/terms_condition">
              <p style={{ color: "#fff" }}>Terms & Conditions</p>
            </a>
            <a href="/contact_us">
              <p style={{ color: "#fff" }}>Contact Us</p>
            </a>
            <a href="/faq">
              <p style={{ color: "#fff" }}>FAQ</p>
            </a>
          </div>

          <div
            className="col  mt-md-4
          "
          >
            <h6 className="mt-1 mx-md-2" style={{ color: "#fff" }}>
              Follow US
            </h6>
            <div className="d-flex align-items-start justify-content-evenly">
              <a
                href="https://www.instagram.com/billpunch_official?igsh=M2gwdWVxZG9idnUy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="insta m-1"
                  alt="Billpunch_Instagram"
                  style={{ height: "3rem", cursor: "pointer" }}
                  src={require("../../../assets/images/Insta.svg")}
                  onClick={handleInstagramClick}
                />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61561070107480"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="facebook m-1"
                  alt="Billpunch_facebook"
                  style={{ height: "3rem" }}
                  src={require("../../../assets/images/Facebook.svg")}
                />
              </a>

              <a
                href="https://www.linkedin.com/company/103316744/admin/feed/posts/?feedType=following"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="linkdin m-1"
                  alt="Billpunch_linkdin"
                  style={{ height: "3rem" }}
                  src={require("../../../assets/images/Linkdin.svg")}
                />
              </a>
              <a
                href="https://www.youtube.com/@Billpunch"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="youtube m-1"
                  alt="Billpunch_youtube"
                  style={{ height: "3rem" }}
                  src={require("../../../assets/images/Youtube.svg")}
                />
              </a>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </>
  );
};

export default Footer;
